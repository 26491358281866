
    <template>
      <section class="content element-doc">
        <h2>安装</h2>
<h3>npm 安装</h3>
<p>推荐使用 npm 的方式安装，它能更好地和 <a href="https://webpack.js.org/">webpack</a> 打包工具配合使用。</p>
<pre><code class="hljs language-shell">npm i element3 -S
</code></pre>
<h3>CDN</h3>
<p>目前可以通过 <a href="https://unpkg.com/element3">unpkg.com/element3</a> 获取到最新版本的资源，在页面上引入 js 和 css 文件即可开始使用。</p>
<pre><code class="hljs language-html"><span class="hljs-comment">&lt;!-- 引入样式 --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">link</span>
  <span class="hljs-attr">rel</span>=<span class="hljs-string">&quot;stylesheet&quot;</span>
  <span class="hljs-attr">href</span>=<span class="hljs-string">&quot;https://unpkg.com/element3/lib/theme-chalk/index.css&quot;</span>
/&gt;</span>
<span class="hljs-comment">&lt;!-- 引入组件库 --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">&quot;https://unpkg.com/element3&quot;</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
<div class="tip">
<p>我们建议使用 CDN 引入 Element3 的用户在链接地址上锁定版本，以免将来 Element3 升级时受到非兼容性更新的影响。锁定版本的方法请查看 <a href="https://unpkg.com">unpkg.com</a>。</p>
</div>
<h3>Hello world</h3>
<p>通过 CDN 的方式我们可以很容易地使用 Element3 写出一个 Hello world 页面。<a href="https://codepen.io/imjustaman/pen/abZajYg">在线演示</a></p>
<iframe height="265" style="width: 100%;" scrolling="no" title="Element3 Demo" src="https://codepen.io/imjustaman/embed/abZajYg?height=265&theme-id=light&default-tab=html,result" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href='https://codepen.io/imjustaman/pen/abZajYg'>Element3 Demo</a> by ImJustAMan
  (<a href='https://codepen.io/imjustaman'>@imjustaman</a>) on <a href='https://codepen.io'>CodePen</a>.
</iframe>
<p>如果是通过 npm 安装，并希望配合 webpack 使用，请阅读下一节：<a href="/#/zh-CN/component/quickstart">快速上手</a>。</p>

      </section>
    </template>
    
  